
import robonomics from "../robonomics";
import Video from "../components/Video.vue";
import Account from "../components/Account.vue";
import Sender from "../components/Sender.vue";
import config from "../config";
// import StickyEventListener from 'sticky-event-listener';

export default {
  components: {
    Video,
    Account,
    Sender
  },
  data() {
    return {
      isReady: false,
      discord: config.discord,
      videoStick: false
    };
  },
  methods: {
    stickPopupVideo() {
      var vid = document.querySelector("#video"),
        isScrolling,
        o = this;

      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(function () {
        o.videoStick = window.pageYOffset - vid.offsetTop > 800;
      }, 66);
    },

    closePopupVideo() {
      this.videoStick = false;
      window.removeEventListener("scroll", this.stickPopupVideo);
      window.removeEventListener("resize", this.stickPopupVideo);
    }
  },
  created() {
    robonomics.onReady(() => {
      this.isReady = true;
    });
  },
  mounted() {
    window.addEventListener("scroll", this.stickPopupVideo);
    window.removeEventListener("resize", this.stickPopupVideo);
  }
};
