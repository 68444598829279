
import robonomics from "../robonomics";
import config from "../config";

export default {
  props: ["loader"],
  data() {
    return {
      text: "",
      isSubscription: false,
      discord: config.discord,
      error: false
    };
  },
  created() {
    setInterval(() => {
      this.isSubscription = robonomics.accountManager.subscription;
    }, 1000);
  },
  watch: {
    text() {
      if (this.text.length > 50) {
        this.error = this.$t("form.error");
      } else {
        this.error = false;
      }
    }
  },
  methods: {
    submit() {
      if (!this.error) {
        this.$emit("submit", this.text);
      }
    }
  }
};
