
import config from "../config";

export default {
  data() {
    return {
      src: config.stream
    };
  }
};
